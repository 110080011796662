<template>
  <div v-if="loaded">
    <component :key="view" :data="editData" :services="editServices" :is="view" />
  </div>
</template>
<script>
export default {
  components: {
    old () { return import('./old') },
    new () { return import('./new') }
  },
  props: { data: Object, services: Array },
  data () {
    return {
      view: '',
      editServices: this.getEditServices(),
      editData: this.getEditData(),
      loaded: false
    }
  },
  computed: {

  },
  mounted () {
    this.setVersion('new')
  },
  methods: {
    getEditServices () {
      if (this.services) {
        return JSON.parse(JSON.stringify(this.services))
      }
    },
    getEditData () {
      if (this.data) {
        return JSON.parse(JSON.stringify(this.data))
      }
    },
    setReload (view) {
      if (typeof this.editData === 'undefined' || typeof this.editData.ID === 'undefined') {
        this.view = view
        this.loaded = true
        return false
      }
      this.getServices(function () {
        this.getData(this.editData.ID)
        this.view = view
      }.bind(this))
    },
    setVersion (view) {
      this.loaded = false
      if (view === 'old') {
        this.setReload(view)
      } else {
        this.view = view
        this.loaded = true
      }
    },
    getData (ID) {
      this.$flix_post({
        url: 'assistent/get_data',
        data: {
          user: this.$store.getters.user.md5_id,
          ID: ID
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.editData = ret.data[1]
            this.editData.ID = ID
          }
          this.loaded = false
        }.bind(this)
      })
    },
    getServices (callback) {
      this.$flix_post({
        url: 'assistent/get_services',
        data: {
          user: this.$store.getters.user.md5_id
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.editServices = ret.data[1]
          }
          callback()
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
